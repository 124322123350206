/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React from 'react';
import { object, string, bool } from 'prop-types';
import Grid from '@material-ui/core/Grid';
import loadable from '@loadable/component';
// helpers
import { withStyles } from '@material-ui/core/styles';
import ReactMarkdown from 'react-markdown';
import ABTest from '../AB_Testing/hocs/ABTesting';
import isEmptyReference from '../../../helpers/contentstack/isEmptyReference';
import BlockHeading from '../../ContentTypeComponents/BlockHeading';
import UniversalHomepageComponent from '../GraphqlHomePage/Partials/UniversalHomepageComponent/UniversalHomepageComponent';
import Cards from '../GraphqlProductPage/Partials/MarketingSpotsPDP/Cards';
import ErrorBoundary from '../../ErrorBoundary/ErrorBoundary';
import UserReviews from '../../ContentTypeComponents/UserReviews';

const HeroSingle = loadable(() => import(/* webpackChunkName: "HeroSingle" */ '../../ContentTypeComponents/HeroSingle/HeroSingle'));
const ThirdsHero = loadable(() => import(/* webpackChunkName: "ThirdsHero" */ '../../ContentTypeComponents/HeroThreeSection/HeroThreeSection'));
const BannerGeneric = loadable(() => import(/* webpackChunkName: "BannerGeneric" */ '../../ContentTypeComponents/GenericBanner/GenericBanner'));
const ThreeUpFeature = loadable(() => import(/* webpackChunkName: "ThreeUpFeature" */ '../../ContentTypeComponents/ThreeUp/ThreeUp'));
const NUpFeature = loadable(() => import(/* webpackChunkName: "NUpFeature" */ '../../ContentTypeComponents/NUp/NUp'));
const NUpSimple = loadable(() => import(/* webpackChunkName: "NUpSimple" */ '../../ContentTypeComponents/NupSimple/NupSimple'));
const GraphqlSeoFooter = loadable(() => import(/* webpackChunkName: "GraphqlSeoFooter" */ '../GraphqlHomePage/Partials/GraphqlSeoFooter/GraphqlSeoFooter'));
const WyngModule = loadable(() => import(/* webpackChunkName: "WyngModule" */ '../../ContentTypeComponents/Wyng/Wyng'));
const SeoBlockContainer = loadable(() => import(/* webpackChunkName: "SeoBlock" */ '../../ContentTypeComponents/SeoBlock/SeoBlockContainer'));
const SubCollection = loadable(() => import(/* webpackChunkName: "SubCollection" */ '../../ContentTypeComponents/SubCollection/SubCollection'));
const VideoModule = loadable(() => import(/* webpackChunkName: "VideoModule" */ '../../ContentTypeComponents/Video/Video'));
const LinkList = loadable(() => import(/* webpackChunkName: "LinkList" */ '../../ContentTypeComponents/LinkList/LinkList'));
const ZoneTenUI = loadable(() => import(/* webpackChunkName: "ZoneTenUI" */ '../GraphqlHomePage/Partials/Banners/ZoneTenUI'));
const ConnectedGraphqlFoodFindPerfect = loadable(() => import(/* webpackChunkName: "ConnectedGraphqlFoodFindPerfect" */ '../GraphqlHomePage/Partials/GraphqlFindAGiftFast/GraphqlFoodGiftFinder'));
const QaListContainer = loadable(() => import(/* webpackChunkName: "QaListContainer" */ '../../ContentTypeComponents/QaList/QaListContainer'));
const ImageLinkListFeature = loadable(() => import(/* webpackChunkName: "ImageLinkListFeature" */ '../ImageLinkListFeatureBlock/ImageLinkListFeature'));
const BottomMultipleBanner = loadable(() => import(/* webpackChunkName: "BottomMultipleBanner" */ '../ImageLinkListFeatureBlock/BottomMultipleBanner'));
const SubNavigation = loadable(() => import(/* webpackChunkName: "SubNavigation" */ '../GraphqlCommonComponents/SubNavigation'));
const GraphqlDisclaimer = loadable(() => import(/* webpackChunkName: "Disclaimer" */ '../GraphqlCategoryPage/Partials/GraphqlDisclaimer/GraphqlDisclaimer'));
const TemplateJasonwuSlider = loadable(() => import(/* webpackChunkName: "ImageSlider" */ '../GraphqlTemplatePage/Partials/TemplateJasonwuConnect/TemplateJasonwuSlider'));
const FAQBlockContainer = loadable(() => import(/* webpackChunkName: "FAQBlockContainer" */ '../GraphqlCommonComponents/FAQBlockV2/FAQBlockContainer'));
const TwoSpot = loadable(() => import(/* webpackChunkName: "TwoSpot" */ '../../ContentTypeComponents/TwoSpot/TwoSpot'));
const InfoBlock = loadable(() => import(/* webpackChunkName: "InfoBlock" */ '../SubscriptionPageV2/LandingPage/InfoBlock'));

const styles = (theme) => ({
    sliderContainer: {
        width: '97%',
        margin: '0 auto',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '95%',
        },
    },
    disclaimerContainer: {
        maxWidth: '1400px',
        margin: '0 auto',
    },
    markdown: {
        width: 'auto',
        maxWidth: '1400px',
        margin: '0 auto',
        padding: '1em',
        '& p': {
            margin: '10px 0px',
        },
        '& a': {
            color: '#2f2f2f',
        },
        '& h1, h2, h3, h4, h5, h6': {
            margin: '5px 0px',
        },
        '& hr': {
            marginBottom: '18px',
        },
        overflowX: 'auto',
    },
    markdownUl: {
        '& ul': {
            margin: '10px',
            lineHeight: '24px',
            paddingLeft: '24px',
            '& li': {
                margin: '10px 0px',
                fontSize: '16px',
            },
        },
        '& a': {
            color: '#2f2f2f',
        },
        '& img': {
            width: '100%',
        },
    },
    markdownTable: {
        '&': {
            overflowX: 'auto',
        },
        '& h1, h2, h3, h4, h5, h6': {
            margin: '10px 0px',
        },
        '& table, th, td': {
            border: '1px solid #7c7c70',
            padding: '16px',
        },
        '& table': {
            width: '100%',
            borderSpacing: '0',
            borderCollapse: 'collapse',
            '& thead': {
                '& tr': {
                    backgroundColor: '#2f2f2f',
                    '& th': {
                        color: '#ffffff',
                        textAlign: 'center',
                        fontSize: '14px',
                        textTransform: 'uppercase',
                        fontWeight: '700',
                        '&:first-child': {
                            minWidth: '15vw',
                        },
                    },
                },
            },
            '& tbody': {
                padding: '14px 0 7px 0',
                textAlign: 'center',
                '& tr': {
                    '&:nth-of-type(even)': {
                        backgroundColor: '#eee',
                    },
                    '& th': {
                        textAlign: 'center',
                        padding: '14px 0 9px 0',
                    },
                    '& td': {
                        fontSize: '14px',
                        textAlign: 'center',
                        padding: '14px 0 9px 0',
                    },
                },
            },
        },
    },
    tableTitle: {
        fontWeight: 900,
        fontSize: '0.67em',
    },
    table: {
        border: '1px solid #7c7c70',
    },
    tableBody: {
        '&:nth-of-type(even)': {
            backgroundColor: '#eee',
        },
    },
    tableHead: {
        backgroundColor: '#2f2f2f',
    },
    tableCellHead: {
        fontSize: '14px',
        textTransform: 'uppercase',
        fontWeight: '700',
        textAlign: 'center',
        color: '#ffffff',
        border: '1px solid #7c7c70',
    },
    tableCell: {
        fontSize: '14px',
        textAlign: 'center',
        padding: '14px 0 7px 0',
        border: '1px solid #7c7c70',
    },

});

const DepartmentPageBlock = ({
    brand, block, ssrDeviceType, presentationFamily, classes, isCelebrationsLander,
}) => {
    const blockKey = Object.keys(block)[0];
    const blockObj = block[blockKey];

    // Run a check to see that a block's references are deployed to the same environment
    if (isEmptyReference(blockObj, blockKey, 'DepartmentPageBlock.js')) return null;
    switch (blockKey) {
        case 'hero_single':
            return <HeroSingle data={blockObj} />;

        case 'hero':
            return <HeroSingle data={blockObj} />;

        case 'a_b_testing_block':
            return <ABTest block={blockObj} ssrDeviceType={ssrDeviceType} presentationFamily={presentationFamily} />;

        case 'hero_three_section':
            return <ThirdsHero data={blockObj} />;

        case 'generic_banner':
            return <BannerGeneric data={blockObj} />;

        case '3_up':
            return <ThreeUpFeature data={blockObj} />;

        case 'tabular_data':
            return <ReactMarkdown source={blockObj.table} className={`${classes.markdown} ${classes.markdownTable} ${classes.pageBreak}`} escapeHtml={false} />;

        case 'n_up':
            return (
                <>
                    {blockObj?.heading && <BlockHeading data={blockObj} />}
                    <NUpFeature data={blockObj} />
                </>
            );

        case 'nup':
            return (
                <>
                    {blockObj?.heading && <BlockHeading data={blockObj} />}
                    <NUpSimple data={blockObj} />
                </>
            );

        case 'n_up_simple':
            return (
                <>
                    {blockObj?.heading && <BlockHeading data={blockObj} />}
                    <NUpSimple data={blockObj} />
                </>
            );

        case 'seo':
            return <GraphqlSeoFooter data={blockObj} />;

        case 'seo_copy': // GFB
            return <SeoBlockContainer data={blockObj} />;

        case 'wyng':
            return <WyngModule data={blockObj} />;

        case 'disclaimer':
            return (
                <div className={classes.disclaimerContainer}>
                    <GraphqlDisclaimer disclaimerBlock={blockObj} />
                </div>
            );

        case 'subcollection':
            /*
                Abstracting the blockObj to potentially modify/add values.
                The Celebrations variant adds an object to pass brand:PIM-ids
                so a single Department entry can reference a specific collection
                depending on the brand the user is on.
                See: https://app.contentstack.com/#!/stack/bltdd99f24e8a94d536/content-type/department/en-us/entry/blt7b4c837fca258b21/edit?branch=main
            */
            // eslint-disable-next-line no-case-declarations
            const blockObjExt = JSON.parse(JSON.stringify(blockObj));
            // eslint-disable-next-line no-case-declarations
            if (isCelebrationsLander) {
                const altCollectionObj = blockObjExt.alt_collections?.value || {};
                const altIdIndex = altCollectionObj.findIndex((o) => o.key === brand?.domain);
                blockObjExt.altPimCode = altCollectionObj[altIdIndex].value;
                blockObjExt.reference[0].subcollection_brand = brand?.domain;
            }

            return (
                <>
                    {blockObj.heading && <BlockHeading data={blockObj} />}
                    <SubCollection data={blockObjExt} />
                </>
            );

        case 'video_module':
            return <VideoModule data={blockObj} />;

        case 'zone_10_banner_v2':
            return <ZoneTenUI data={blockObj} ssrDeviceType={ssrDeviceType} />;
        // Mobile Only
        case 'trending_products':
            return null;

        case 'link_list_module_mobile':
            return <LinkList data={blockObj} type="footermenu" />;

        case 'gift_finder_gfb':
            return <ConnectedGraphqlFoodFindPerfect data={blockObj} />;

        case 'qa_list':
            return (
                <Grid container justify="center">
                    <Grid item xs={12} sm={12} md={8} lg={8}>
                        <QaListContainer faqSchema data={(blockObj?.reference?.[0] || {})} skeletonData={blockObj} identifyWidthObj={{ isDesktop: (ssrDeviceType === 'desktop'), isMobile: (ssrDeviceType === 'mobile') }} />
                    </Grid>
                </Grid>
            );
        case 'image_link_block':
            return <ImageLinkListFeature data={blockObj} />;

        case 'bottom_banners':
            return <BottomMultipleBanner data={blockObj} />;

        case 'recently_viewed': {
            // TO-DO WITH SF
            return null;
        }

        case 'sub_navigation':
            return <SubNavigation subnavBlock={blockObj} />;

        case 'footer':
            return <GraphqlDisclaimer disclaimerBlock={blockObj} />;

        case 'faq':
            return <FAQBlockContainer faqData={blockObj} />;

        case '2_spot':
            return <TwoSpot data={blockObj} />;
        // Slider and slider header block
        case 'info_block':
            return <InfoBlock item={blockObj} mapKey="infoBlock" />;
        case 'info_cards':
            return <Cards data={blockObj} mapKey="cardBlock" />;
        case 'slider_block':
            return (
                <Grid container  className={classes.sliderContainer}>
                    <Grid item xs={12} sm={12} md={12} lg={11}>
                        <TemplateJasonwuSlider sliderBlock={block.slider_block} />
                    </Grid>
                </Grid>
            );
        case 'reviews':
            return (
                <ErrorBoundary boundaryName="Reviews Error Boundary">
                    <UserReviews data={blockObj} />
                </ErrorBoundary>
            );
        case 'universal_components':
            return <UniversalHomepageComponent data={blockObj} />;
        default:
            console.error('Unknown DepartmentPage block type in DepartmentPageBlock.js');
            return null;
    }
};

DepartmentPageBlock.propTypes = {
    brand: object.isRequired,
    block: object.isRequired,
    ssrDeviceType: string.isRequired,
    presentationFamily: string.isRequired,
    classes: object.isRequired,
    isCelebrationsLander: bool.isRequired,
};

export default  withStyles(styles)(DepartmentPageBlock);
